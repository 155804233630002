import React from 'react'
import { graphql } from 'gatsby'
import { BackgroundVideo, Checkout, Landing, SEO, Testimonials } from '../../components/common'
import ReactCompareImage from 'react-compare-image'

// Styles
import '../../styles/app.css'

const Index = ({ data, location, pageContext }) => {
    return (
        <>
            <Landing>
                <SEO
                    title="I migliori LUTs per video FPV"
                    image="/images/email/lutspackemail.jpg"
                    description="Il pacchetto LUT di NorthFPV è stato creato appositamente per rendere speciali le tue riprese FPV. Grazie al nostro pacchetto di ampliamento LUT potrai facilmente trasformare i tuoi video in riprese di qualità cinematografica, non importa che tu stia usando una action cam gopro o una videocamera professionale. A differenza dei FREE LUT gratuiti che puoi trovare online, i nostri LUT sono studiati per permetterti di ottenere i colori che desideri sui tuoi video con il minor sforzo possibile. Una volta installati, puoi applicare le modifiche di colore con un solo clic in Premiere Pro CC, FCPX, After Effects CC, DaVinci Resolve."
                />
                <BackgroundVideo data={data}/>
                <div className="flex-container mt-2">
                    <div className="flex-item pr-1">
                        <h2 className="luts-title">Dai un nuovo look ai tuoi video FPV</h2>
                        <p>Il pacchetto LUT di NorthFPV è stato creato appositamente per rendere speciali le tue riprese FPV.</p>
                        <p>Grazie al nostro pacchetto di ampliamento LUT potrai facilmente trasformare i tuoi video in riprese di qualità cinematografica, non importa che tu stia usando una GoPro, un'action camera o una videocamera professionale.</p>
                        <p>A differenza dei FREE LUT gratuiti che puoi trovare online, i nostri LUT sono studiati per permetterti di ottenere i colori che desideri sui tuoi video con il minor sforzo possibile. Una volta installati, puoi applicare le modifiche di colore con un solo clic. Questo pacchetto è compatibile con Premiere Pro CC, FCPX, After Effects CC, DaVinci Resolve e altro!</p>
                    </div>
                    <div className="flex-item pl-1">
                        <div>
                            <header className="product-card-header">
                                <div className="product-card-image" style={{ backgroundImage: `url("${data.product.childImageSharp.fixed.srcWebp}")`, }} />
                            </header>
                            <h2 className="luts-card-title">NorthFPV LUTs</h2>
                            <Checkout buttonLabel="Scarica Ora" price="price_1Ho7qyAafUY8FLlUGxUnF8ls" cancelPath="luts"/>
                        </div>
                    </div>
                </div>
                <div className="bg-grey mt-2">
                    <div className="container py-2">
                        <h2 className="luts-card-title">Recensioni</h2>
                        <Testimonials/>
                    </div>
                </div>
                <div className="flex-container-wide mt-2">
                    <div className="flex-item mx-1">
                    <ReactCompareImage leftImage={data.lut1.childImageSharp.fixed.srcWebp} rightImage={data.nolut1.childImageSharp.fixed.srcWebp}/>
                    </div>
                    <div className="flex-item mx-1">
                    <ReactCompareImage leftImage={data.lut2.childImageSharp.fixed.srcWebp} rightImage={data.nolut2.childImageSharp.fixed.srcWebp}/>
                    </div>
                </div>
                <div className="flex-container-wide">
                    <div className="flex-item mx-1">
                    <ReactCompareImage leftImage={data.lut3.childImageSharp.fixed.srcWebp} rightImage={data.nolut3.childImageSharp.fixed.srcWebp}/>
                    </div>
                    <div className="flex-item mx-1">
                    <ReactCompareImage leftImage={data.lut4.childImageSharp.fixed.srcWebp} rightImage={data.nolut4.childImageSharp.fixed.srcWebp}/>
                    </div>
                </div>
                
            </Landing>
        </>
    )
}

export default Index;

export const fixedImage = graphql`
  fragment fixedImage on File {
    childImageSharp {
        fixed(width: 1920, height: 1080) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
  }
`;


export const pageQuery = graphql`
  query {
    poster: file(relativePath: {eq: "landing/poster.png"}) {
        childImageSharp {
          fixed(width: 1920, height: 1080) {
              srcWebp
          }
        }
      }
    videos: file(relativePath: {eq: "landing/trailer_luts.mp4"}) {
        first: videoWebP(maxWidth: 1280, fps: 25) {
            path
            }
        second: videoH264(maxWidth: 1280, fps: 25) {
            path
          }
        }
    lut1: file(relativePath: { eq: "landing/img_1_lut.png" }) {
        ...fixedImage
    }
    nolut1: file(relativePath: { eq: "landing/img_1_nolut.png" }) {
        ...fixedImage
    }
    lut2: file(relativePath: { eq: "landing/img_2_lut.png" }) {
        ...fixedImage
    }
    nolut2: file(relativePath: { eq: "landing/img_2_nolut.png" }) {
        ...fixedImage
    }
    lut3: file(relativePath: { eq: "landing/img_3_lut.png" }) {
        ...fixedImage
    }
    nolut3: file(relativePath: { eq: "landing/img_3_nolut.png" }) {
        ...fixedImage
    }
    lut4: file(relativePath: { eq: "landing/img_4_lut.png" }) {
        ...fixedImage
    }
    nolut4: file(relativePath: { eq: "landing/img_4_nolut.png" }) {
        ...fixedImage
    }
    product: file(relativePath: { eq: "landing/boxlut.jpg" }) {
        childImageSharp {
            fixed(width: 720, height: 720) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
    }
}
`;